<template>
  <div class="m-5 p-3">
    <el-row>
      <el-col :md="15" class="pr-4">
        <div>
          <h2 class="pb-2">{{ $t("MentoringProgram.title") }}</h2>
          <div v-html="$t('MentoringProgram.desc')"></div>
        </div>
      </el-col>
      <el-col :md="9" class="img">
        <el-image
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/mentoring.jpg"
        />
      </el-col>
    </el-row>
    <div>
      <div v-html="$t('MentoringProgram.g')"></div>
      <div v-html="$t('MentoringProgram.g1')"></div>
      <div v-html="$t('MentoringProgram.g2')"></div>
      <div v-html="$t('MentoringProgram.g3')"></div>
      <div v-html="$t('MentoringProgram.g4')"></div>
    </div>
    <div class="pt-5">
      <h4 style="font-weight:bold;">{{ $t("MentoringProgram.fee") }}</h4>
      <ul>
        <li>$20,000 USD/year</li>
        <li>$12,500 USD/semester</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toRoute(name) {
      this.$router.push({
        name: name
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 992px) {
  .img {
    padding-top: 20px;
  }
}
</style>
